export const posts = [
    {
        id: '1',
        title: 'Understanding Windshield Repair vs. Replacement',
        metaTitle: 'Windshield Repair or Replacement: Making the Right Choice',
        excerpt: 'Learn how to determine whether your windshield needs a repair or a full replacement, and understand the factors that influence this decision.',
        slug: 'windshield-repair-vs-replacement',
        tags: ['windshield repair', 'windshield replacement', 'auto glass'],
        metaDescription: 'Deciding between windshield repair and replacement can be challenging. Read our guide to make an informed decision for your vehicle’s windshield.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'The Importance of Professional ADAS Calibration',
        metaTitle: 'Why Professional ADAS Calibration Matters for Your Vehicle',
        excerpt: 'Discover the significance of ADAS calibration after windshield replacement and how it impacts your vehicle’s safety features.',
        slug: 'professional-adas-calibration-importance',
        tags: ['ADAS calibration', 'auto glass safety', 'windshield replacement'],
        metaDescription: 'ADAS calibration is crucial for the safety features of your vehicle. Learn why professional calibration is essential after windshield replacement.',
        lastModified: new Date('2023-12-15').toISOString()
    },
    {
        id: '3',
        title: 'Mobile Auto Glass Services: Convenience and Efficiency',
        metaTitle: 'The Benefits of Mobile Auto Glass Repair and Replacement',
        excerpt: 'Explore the advantages of mobile auto glass services, offering convenience and efficiency without compromising on quality.',
        slug: 'mobile-auto-glass-services-benefits',
        tags: ['mobile auto glass', 'convenience', 'efficient service'],
        metaDescription: 'Mobile auto glass services bring convenience to your doorstep. Discover how these services can save you time and provide efficient repairs.',
        lastModified: new Date('2023-12-20').toISOString()
    },
    {
        id: '4',
        title: 'Tips for Maintaining Your Auto Glass in Extreme Weather',
        metaTitle: 'Maintaining Auto Glass in Extreme Weather Conditions',
        excerpt: 'Learn how to protect and maintain your auto glass during extreme weather conditions to ensure safety and prolong its lifespan.',
        slug: 'maintaining-auto-glass-extreme-weather',
        tags: ['auto glass maintenance', 'extreme weather', 'vehicle safety'],
        metaDescription: 'Extreme weather can pose a threat to your vehicle’s auto glass. Discover essential tips for maintaining your auto glass in harsh weather conditions.',
        lastModified: new Date('2024-1-04').toISOString()
    },
]
