import React from "react";
import autoglassRepairMesaMeta from '../assets/images/meridenmeta.jpg'
import {posts} from "./posts";
import {Box, Button, Flex, Heading, Hide, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {BiSupport, BiWrench} from "react-icons/bi";
import {RiDashboardLine, RiEmotionHappyLine} from "react-icons/ri";
import {FaCarCrash, FaTools} from "react-icons/fa";
import {AiOutlineCar} from "react-icons/ai";
import {MdDirectionsCar, MdOutlineSpeed} from "react-icons/md";
import {GiCarDoor} from "react-icons/gi";

const phoneNumber = '(480) 771-9851';
const telLink = 'tel:4807719851';
const phoneNumberWithDashes = '480-771-9851';

export const SiteData = {
    hoursOfOperation: "Monday to Saturday, 8:00 AM - 6:00 PM",
    city: 'Mesa, AZ',
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    buttonCtaText: () => <Flex>Need Quick Auto Glass Repair? Get Your&nbsp;<Text textDecoration={'underline'}
                                                                                 display={'inline'}>Free
        Quote Today!</Text></Flex>,
    emailFooterText: `For all your Glass Repairing needs, call Auto Glass Repair Mesa at ${phoneNumber}`,
    keywords: 'Auto Glass Repair, windshield replacement, Mesa AZ, car window repair, vehicle glass services.',
    footerText: 'AutoGlassRepairMesa.com, All rights reserved.',
    ogImage: autoglassRepairMesaMeta,
    navLinks: [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            href: '/services'
        },
        {
            title: 'Blog',
            href: '/blog'
        },
        {
            title: 'About',
            href: '/about'
        },
        {
            title: 'Contact Us',
            href: '/contact'
        },
        {
            title: 'Careers',
            href: '/careers',
            hideFromTopNav: true
        },
    ],
    signupForm: {
        intro: () => <Text>Welcome to AutoGlassRepairMesa.com, Mesa's leading auto glass repair service. Our team of
            experienced technicians specializes in windshield repair and replacement, ensuring top-quality service for
            every vehicle. Contact us at <Link href={telLink}
                                               textDecoration={'underline'}>{phoneNumber}</Link> for a free quote and
            speedy service.</Text>,
        servicesTitle: 'Our Services:',
    },
    homepage: {
        url: 'https://www.autoglassrepairmesa.com',
        metaData: {
            title: 'AutoGlassRepairMesa.com: Your Trusted Auto Glass Repair Experts in Mesa, AZ',
            description: 'Professional auto glass repair and windshield replacement services in Mesa, AZ. Quality, efficiency, and reliability at AutoGlassRepairMesa.com.'
        },
        h1: 'Quality Auto Glass Repair Services in Mesa, AZ',
        subtitle: 'Expert Windshield Replacement and Repair - Ensuring Your Safety on the Road',
        servicesTitle: 'Why Choose AutoGlassRepairMesa.com for Your Vehicle’s Glass Needs',
        values: [
            {
                id: 0,
                number: "10+ Years",
                name: "Experience in Glass Repair",
                icon: FaTools,
            },
            {
                id: 1,
                number: "100%",
                name: "Customer Satisfaction",
                icon: RiEmotionHappyLine,
            },
            {
                id: 2,
                number: "Same Day",
                name: "Fast and Reliable Repairs",
                icon: MdOutlineSpeed,
            },
            {
                id: 3,
                number: "24/7",
                name: "Emergency Services",
                icon: BiSupport,
            }
        ],
        services: [
            {
                id: '0',
                text: () => (
                    <Text>
                        <b>Comprehensive Windshield Repair:</b> Addressing chips and cracks with precision to restore
                        your windshield's integrity and clarity.
                        Our dedicated team of technicians employs advanced techniques and state-of-the-art equipment to
                        ensure that your windshield is repaired effectively.
                        From minor chips to extensive cracks, we prioritize safety and quality in every repair job,
                        providing you with peace of mind on the road.
                    </Text>
                )
            },
            {
                id: '1',
                text: () => (
                    <Text>
                        <b>Windshield Replacement:</b> Providing high-quality windshield replacements for all types of
                        vehicles, ensuring safety and visibility.
                        Whether your windshield is damaged beyond repair or you simply prefer a new installation, our
                        experienced technicians are here to assist you.
                        We offer a wide selection of windshield options, ensuring a perfect fit for your vehicle and
                        reliable protection for you and your passengers.
                    </Text>
                )
            },
            {
                id: '2',
                text: () => (
                    <Text>
                        <b>Mobile Auto Glass Services:</b> Offering convenient mobile services to repair your auto glass
                        wherever you are in Mesa, AZ.
                        Our mobile service brings professional auto glass repair directly to your location, saving you
                        time and hassle.
                        Whether you're at home, work, or on the road, our skilled technicians will promptly address your
                        auto glass needs, ensuring your safety and satisfaction.
                    </Text>
                )
            },
            {
                id: '3',
                text: () => (
                    <Text>
                        <b>Expert Windshield Inspection:</b> Conducting thorough windshield inspections to identify any
                        issues and recommend appropriate solutions.
                        Our certified technicians utilize industry-leading techniques and equipment to assess the
                        condition of your windshield,
                        ensuring that any potential safety hazards are addressed promptly. Trust us to keep your
                        windshield in optimal condition for a safe and comfortable driving experience.
                    </Text>
                )
            }
        ],

        content: [
            <>
                <Heading as="h2" size={'md'} pb='2'>Mesa's Go-To Experts in Auto Glass Repair</Heading>
                <Text>
                    Welcome to Mesa's premier auto glass repair destination, Auto Glass Repair Mesa! Nestled in the
                    heart of Mesa, AZ, we are your first responders for all auto glass needs. From a tiny chip in your
                    windshield to a full replacement, we are known for our swift response, exceptional service, and
                    budget-friendly pricing. Our commitment goes beyond just repairing glass; it's about restoring your
                    safety and peace of mind on the road.

                    Whether it's an urgent repair or a standard replacement, our team comes equipped with the latest
                    tools and a wealth of knowledge, ready to address your auto glass issues promptly and efficiently.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Comprehensive Auto Glass Services in Mesa</Heading>
                <Text>
                    Auto troubles can strike at any time, but worry not! Our services at Auto Glass Repair Mesa are
                    always available. Day or night, we're just a phone call away. Reach out to us at <Link
                    href={telLink}>{phoneNumber}</Link> for quick and reliable auto glass services, any time
                    of the day.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Your First Choice for Auto Glass in Mesa</Heading>
                    If you're facing auto glass troubles in Mesa, AZ, simply call Auto Glass Repair Mesa at <Link
                    href={telLink}>{phoneNumber}</Link>. Remember this number for hassle-free service and
                    immediate quotes. Payment should be the least of your worries, so we offer flexible options to suit
                    your needs. Whether you need emergency repairs or routine maintenance, our <a
                    href="https://www.autoglassrepairmesa.com" target={'_blank'} rel={'noreferrer'}>auto glass
                    services</a> are always ready to assist.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Tailored Auto Glass Solutions in Mesa</Heading>
                <Text>
                    Here's what we offer at Auto Glass Repair Mesa:
                    <UnorderedList>
                        <ListItem><strong><Link href={"/windshield-repair"}>Windshield Repair</Link>:</strong> Quick and
                            efficient repair
                            for chips and cracks, keeping you safe on the road.</ListItem>
                        <ListItem><strong><Link href={"/windshield-replacement"}>Windshield
                            Replacement</Link>:</strong> Comprehensive
                            replacement services for all vehicle types, ensuring perfect fit and clarity.</ListItem>
                        <ListItem><strong><Link href={"/mobile-auto-glass-services"}>Mobile Auto Glass Services</Link>:</strong>
                            On-the-go repairs and replacements, bringing convenience right to your doorstep in Mesa, AZ.</ListItem>
                        <ListItem><strong><Link href={"/rear-window-repair"}>Rear Window Repair and Replacement</Link>:</strong>
                            Specialized care for your vehicle's rear window, offering both repair and replacement
                            solutions.</ListItem>
                        <ListItem><strong><Link href={"/side-window-repair"}>Side Window Repair and Replacement</Link>:</strong> Expert
                            handling of side window damages, ensuring your vehicle’s safety and aesthetics.</ListItem>
                        <ListItem><strong><Link href={"/adas-calibration"}>ADAS Calibration</Link>:</strong> Precision
                            calibration of
                            Advanced Driver Assistance Systems after windshield work, for your safety on the
                            road.</ListItem>
                    </UnorderedList>
                    At Auto Glass Repair Mesa, we're dedicated to providing personalized <a
                    href={"https://www.thorntonautoglass.com/"} target={"_blank"} rel={"noreferrer"}>auto
                    glass</a> solutions for every
                    Mesa driver.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Quality Service Without the Hefty Price Tag</Heading>
                <Text>
                    At Auto Glass Repair Mesa, we believe in providing top-tier service without breaking the bank.
                    Expect transparent pricing and no hidden fees – only fair, honest rates that reflect our commitment
                    to quality and customer satisfaction. Our team is dedicated to delivering exceptional value without
                    compromising on quality.
                    We understand the importance of affordability without sacrificing excellence, which is why we
                    prioritize
                    cost-effective solutions tailored to your needs.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Skilled Technicians for All Your Auto Glass Needs</Heading>
                <Text>
                    Our team of experienced technicians specializes in all aspects of auto glass repair and replacement.
                    We use the best materials and state-of-the-art techniques to ensure optimal results for every
                    vehicle. With years of hands-on experience, our technicians have honed their skills to handle
                    a wide range of auto glass issues efficiently and effectively. Trust us to deliver precision
                    craftsmanship and reliable service for all your auto glass needs.
                </Text>

                <Heading as="h2" size={'md'} pb='2'>Dedicated to Your Safety and Satisfaction</Heading>
                <Text>
                    Your safety is our top priority at Auto Glass Repair Mesa. We strive for excellence in every job,
                    ensuring that all repairs and replacements meet the highest standards. Depend on us for reliable and
                    timely services in Mesa, AZ. From windshield repairs to complete replacements, we are committed to
                    providing personalized attention and superior results that exceed your expectations. Experience
                    peace
                    of mind knowing that your vehicle is in the hands of skilled professionals who prioritize your
                    safety
                    and satisfaction above all else.
                </Text>


                <Heading as="h2" size={'md'} pb='2'>Auto Glass Repair Mesa: Always Here for You</Heading>
                <Text>
                    Facing auto glass issues in Mesa, AZ? We're just a call away at Auto Glass Repair Mesa. Dial <Link
                    href={telLink}>{phoneNumber}</Link> for immediate assistance. We're ready to provide a free quote
                    and demonstrate why we're the
                    trusted choice in Mesa. With various payment options, we make sure our services are accessible and
                    stress-free. Remember, for any auto glass needs, we're just a phone call away.
                </Text>

                <Button as={'a'} href={telLink} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide
                    below='md'>Immediate</Hide> Assistance</Button>
            </>
        ],
        footerHeading: 'Contact AutoGlassRepairMesa.com',
        footerText: () => <>For professional auto glass services in Mesa and the surrounding areas, get in touch with us
            today. Call or use our online contact form for a free quote and experience the AutoGlassRepairMesa.com
            difference.</>,
        footerText2: 'Safety and Quality in Every Repair and Replacement',
        servicesHeader: 'Our Comprehensive Range of Auto Glass Services',
        servicesSubtitle: () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our <Link
            href={'/services'}>services</Link> – from windshield repair to full replacements, AutoGlassRepairMesa.com is
            your go-to solution for all auto glass needs in Mesa, AZ.</Text>,
        featuredServices: [
            {
                name: 'Windshield Repair',
                description: 'Expert repair services for chips and cracks, enhancing your windshield’s durability and clarity. Utilizing advanced resin injection techniques for a seamless finish. Our technicians are skilled in repairing various types of damage, restoring the integrity of your windshield and improving visibility on the road.',
                icon: BiWrench,
                path: '/windshield-repair',
                linkTitle: 'Windshield Repair'
            },
            {
                name: 'Windshield Replacement',
                description: "Comprehensive windshield replacement for all vehicle types. We use high-quality glass and adhesives, ensuring a perfect fit and long-lasting results. Our replacement process is meticulous, from removing the old windshield to installing the new one, ensuring your vehicle's structural integrity and safety.",
                icon: FaCarCrash,
                path: '/windshield-replacement',
                linkTitle: 'Windshield Replacement'
            },
            {
                name: 'Mobile Auto Glass Services',
                description: 'Convenience at your doorstep with our mobile auto glass repair and replacement services. Ideal for busy schedules or emergency situations in Mesa, AZ. Our mobile services bring professional auto glass solutions directly to your location, saving you time and hassle.',
                icon: MdDirectionsCar,
                path: '/mobile-auto-glass-services',
                linkTitle: 'Mobile Auto Glass Services'
            },
            {
                name: 'Rear Window Repair',
                description: 'Specialized services for rear window issues, providing both repair and full replacement options based on your vehicle’s needs. Our technicians assess the damage to your rear window and recommend the most suitable solution to restore its functionality and safety features.',
                icon: GiCarDoor,
                path: '/rear-window-repair',
                linkTitle: 'Rear Window Repair'
            },
            {
                name: 'Side Window Repair',
                description: 'Addressing side window damages efficiently. Whether it’s a minor repair or a full replacement, we ensure top-notch service and safety. Our technicians are equipped to handle various types of side window damage, restoring the structural integrity and security of your vehicle.',
                icon: AiOutlineCar,
                path: '/side-window-repair',
                linkTitle: 'Side Window Repair'
            },
            {
                name: 'ADAS Calibration',
                description: 'Advanced Driver Assistance Systems (ADAS) calibration post windshield replacement, ensuring your vehicle’s safety features function correctly. Our calibration process ensures that your ADAS features are accurately calibrated, providing optimal performance and safety on the road.',
                icon: RiDashboardLine,
                path: '/adas-calibration',
                linkTitle: 'ADAS Calibration'
            },
        ],

    },
    careers: {
        "url": "https://www.autoglassrepairmesa.com/careers/",
        "metaData": {
            "title": "Join Our Dynamic Team - Auto Glass Repair Careers in Mesa, AZ",
            "description": "Embark on a fulfilling career in auto glass repair with Auto Glass Repair Mesa. We're looking for passionate individuals to join our expert team in Mesa, AZ. Discover your potential with us."
        },
        "h1": "We're Hiring - Shape Your Future with Auto Glass Repair Mesa",
        "h2": [
            "Why Choose a Career at Auto Glass Repair Mesa?",
            "Explore Our Latest Auto Glass Technician Vacancies",
            "Our Dedication to Employee Development and Satisfaction"
        ],
        "headingText": {
            "whyJoin": "Join a workplace where your talent in auto glass repair and customer service is deeply valued. Be part of a team committed to excellence in car window repair and windshield replacement services in Mesa, AZ.",
            "openings": "Check out our current openings for skilled auto glass technicians and find a position that suits your skills and career goals.",
            "commitment": "At Auto Glass Repair Mesa, we foster a culture of growth and support, ensuring every team member has the opportunity to succeed. Your professional development is our priority."
        },
        "content": [
            <Text>Welcome to the Careers page at <Link href={'/'}>Auto Glass Repair Mesa</Link>, your gateway to a
                thriving career in the auto glass repair industry. We're more than just a team; we're a family dedicated
                to delivering top-notch windshield repair and replacement services in Mesa, AZ.</Text>,
            <Text>At <Link href={'/'}>Auto Glass Repair Mesa</Link>, we believe the foundation of our success is our
                people. We're looking for committed professionals who are enthusiastic about delivering superior auto
                glass services. Whether you're a seasoned technician or keen to learn, we provide an environment where
                your skills will be appreciated and nurtured.</Text>,
            <Text>Our team enjoys a collaborative culture that promotes both personal and professional development. We
                offer comprehensive training, competitive salaries, and the chance to work with the latest tools and
                technologies in the auto glass industry. Our goal is to create a safe, fulfilling, and enjoyable
                workplace for all.</Text>,
            <Text>As a vital part of the Mesa community, we pride ourselves on serving our customers with integrity and
                professionalism. This commitment to excellence also extends to our team members, whom we support with a
                positive work environment, team respect, and career advancement opportunities.</Text>,
            <Text>Ready to start your career with a leading auto glass repair provider in <a
                href='https://en.wikipedia.org/wiki/Mesa,_Arizona'>Mesa, AZ</a>? Explore our current job openings and
                find your place in our growing team. From on-site repairs to customer service roles, there's a spot for
                you at Auto Glass Repair Mesa.</Text>,
            <Text><a href='https://www.autoglassrepairmesa.com/careers/'>Find your next career challenge</a> with us.
                Join a team that's making a difference with every repair and every satisfied customer. At <Link
                    href={'/'}>Auto Glass Repair Mesa</Link>, we're more than a business; we're a community. Apply now
                and drive your career forward.</Text>,
        ]
    },
    about: {
        url: "https://www.autoglassrepairmesa.com/about/",
        metaData: {
            title: "About Us - AutoGlassRepairMesa.com | Professional Auto Glass Services in Mesa, AZ",
            description: "Learn more about AutoGlassRepairMesa.com, your reliable experts for auto glass repair and replacement in Mesa, AZ."
        },
        h1: "About AutoGlassRepairMesa.com",
        h2: [
            "Leading the Way in Auto Glass Repair in Mesa, AZ",
            "Committed to Excellence and Customer Satisfaction",
            "Why Choose Us for Your Auto Glass Needs"
        ],
        headingText: {
            commitment: "Dedicated to providing top-notch auto glass repair and replacement services, we ensure each job meets the highest standards of quality and safety.",
            team: "Our team of skilled technicians brings years of experience and expertise to every auto glass challenge, using the latest techniques and tools for optimal results.",
            services: "We offer a comprehensive range of auto glass services, including windshield repair, full windshield replacement, mobile services, and more. Our focus on quality and efficiency makes us the go-to choice for drivers in Mesa, AZ."
        },
        services: [
            {
                "id": "0",
                "title": 'Windshield Repair',
                "href": '/windshield-repair',
                "icon": BiWrench,
                "description": <Text>Expert repair services for chips and cracks, enhancing your windshield’s durability
                    and clarity. Utilizing advanced resin injection techniques for a seamless finish. Our technicians
                    are
                    trained to assess and repair various types of windshield damage, restoring the integrity of your
                    windshield
                    and improving visibility on the road.</Text>
            },
            {
                "id": "1",
                "title": 'Windshield Replacement',
                "href": '/windshield-replacement',
                "icon": FaCarCrash,
                "description": <Text>Comprehensive windshield replacement for all vehicle types. We use high-quality
                    glass and adhesives, ensuring a perfect fit and long-lasting results. Our replacement process
                    involves
                    meticulous attention to detail, from removing the old windshield to installing the new one, ensuring
                    the structural integrity and safety of your vehicle.</Text>
            },
            {
                "id": "2",
                "title": 'Mobile Auto Glass Services',
                "href": '/mobile-auto-glass-services',
                "icon": MdDirectionsCar,
                "description": <Text>Convenience at your doorstep with our mobile auto glass repair and replacement
                    services. Ideal for busy schedules or emergency situations in Mesa, AZ. Our mobile services bring
                    professional auto glass solutions directly to your location, saving you time and hassle.</Text>
            },
            {
                "id": "3",
                "title": 'Rear Window Repair and Replacement',
                "href": '/rear-window-repair',
                "icon": GiCarDoor,
                "description": <Text>Specialized services for rear window issues, providing both repair and full
                    replacement options based on your vehicle’s needs. Our technicians assess the damage to your rear
                    window and recommend the most suitable solution to restore its functionality and safety
                    features.</Text>
            },
            {
                "id": "4",
                "title": 'Side Window Repair and Replacement',
                "href": '/side-window-repair',
                "icon": AiOutlineCar,
                "description": <Text>Addressing side window damages efficiently. Whether it’s a minor repair or a full
                    replacement, we ensure top-notch service and safety. Our technicians are equipped to handle various
                    types of side window damage, restoring the structural integrity and security of your vehicle.</Text>
            },
            {
                "id": "5",
                "title": 'ADAS Calibration',
                "href": '/adas-calibration',
                "icon": RiDashboardLine,
                "description": <Text>Advanced Driver Assistance Systems (ADAS) calibration post windshield replacement,
                    ensuring your vehicle’s safety features function correctly. Our calibration process ensures that
                    your
                    ADAS features are accurately calibrated, providing optimal performance and safety on the
                    road.</Text>
            }
        ],

    },
    services: {
        url: 'https://www.autoglassrepairmesa.com/services/',
        metaData: {
            title: 'Auto Glass Repair and Replacement in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Explore our wide range of auto glass services in Mesa, AZ. From windshield repair to ADAS calibration, we cover all your auto glass needs.'
        },
        h1: 'Expert Auto Glass Services in Mesa, AZ',
        h2: 'Our Professional Auto Glass Repair and Replacement Services',
        subtext: 'At AutoGlassRepairMesa.com, we offer a variety of auto glass services tailored to meet your specific needs, ensuring safety and quality.',
        footerHeading: 'Get Your Auto Glass Fixed with Us',
        footerText2: () => <>For top-quality auto glass repair and replacement services, contact us at <Link
            href={telLink}>{phoneNumberWithDashes}</Link>. Trust us for quick, reliable, and efficient service.</>,
        footerText: () => <>Remember, when it comes to auto glass, your safety is our priority. With
            AutoGlassRepairMesa.com, you're in good hands. Reach out today!</>,
        content: <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Comprehensive Auto Glass Solutions in Mesa, AZ</Heading>
                <Text>Whether you're dealing with a cracked windshield, a chipped rear window, or need a full glass
                    replacement, our team of certified technicians is equipped to handle all types of auto glass
                    issues.</Text>

                <Heading as="h3" size="lg">Why Trust AutoGlassRepairMesa.com?</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Quality Materials:</b> We use only the best materials and glass for durable and
                        long-lasting repairs and replacements.</Text></ListItem>
                    <ListItem><Text><b>Advanced Technology:</b> Leveraging the latest tools and techniques for precise
                        and efficient service.</Text></ListItem>
                    <ListItem><Text><b>Experienced Technicians:</b> Our team is highly trained and experienced in
                        handling a variety of auto glass concerns.</Text></ListItem>
                    <ListItem><Text><b>Customer-Centric Service:</b> We prioritize your convenience and satisfaction in
                        every job we undertake.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">A Range of Auto Glass Services</Heading>
                <List spacing={3}>
                    <ListItem>
                        <Text>
                            <Link href="/windshield-repair" style={{fontWeight: "bold"}}>Windshield Repair and
                                Replacement:</Link> Addressing everything from minor chips to significant cracks and
                            full windshield replacements.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <Link href="/mobile-auto-glass-services" style={{fontWeight: "bold"}}>Mobile Auto Glass
                                Services:</Link> Providing convenient on-site repairs and replacements wherever you are
                            in Mesa, AZ.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <Link href="/rear-and-side-window-repair" style={{fontWeight: "bold"}}>Rear and Side Window
                                Solutions:</Link> Comprehensive services for all rear and side window issues, ensuring
                            your vehicle's integrity and safety.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <Link href="/adas-calibration" style={{fontWeight: "bold"}}>ADAS
                                Calibration:</Link> Ensuring that your vehicle's advanced safety features are accurately
                            calibrated after a windshield replacement.
                        </Text>
                    </ListItem>
                </List>
            </Stack>
        </Box>,
    },
    contact: {
        url: 'https://www.autoglassrepairmesa.com/contact/',
        metaData: {
            title: 'Contact Us - AutoGlassRepairMesa.com | Reach Our Auto Glass Experts in Mesa, AZ',
            description: 'Get in touch with AutoGlassRepairMesa.com for all your auto glass repair needs in Mesa, AZ. Contact us today for a free quote.'
        },
        h1: 'Contact AutoGlassRepairMesa.com',
        subtitle: 'Reach out to our expert team for quick and reliable auto glass repair services in Mesa, AZ.',
        sectionHeading: 'Ready to Assist You with Your Auto Glass Needs',
        signUpDescription: 'Fill out our online form to contact us, and we will get back to you promptly with a solution tailored to your needs.',
        feedbackText: 'We value your feedback and inquiries. Don’t hesitate to contact us with any questions or to schedule a service.',
        closingText: <>For expert auto glass repair, reach out to us for a free quote.</>,
        valueProps: [
            {
                title: 'Quick Response Time:',
                description: 'We respond swiftly to all inquiries and service requests, ensuring timely solutions for your auto glass needs.'
            },
            {
                title: 'Experienced Technicians:',
                description: 'Our team consists of highly skilled professionals, equipped to handle all types of auto glass repairs and replacements.'
            },
            {
                title: 'Customer Satisfaction:',
                description: 'Dedicated to delivering the best service experience, we prioritize your satisfaction with every job we undertake.'
            },
            {
                title: 'Quality Assurance:',
                description: 'We use top-quality materials and state-of-the-art equipment to ensure durable and reliable auto glass repairs.'
            },
            {
                title: 'Comprehensive Services:',
                description: 'From simple chip repairs to complete windshield replacements, we offer a wide range of auto glass services.'
            },
        ]
    },
    blog: {
        url: 'https://www.autoglassrepairmesa.com/blog/',
        metaData: {
            title: 'Auto Glass Repair Blog | Tips & Advice | AutoGlassRepairMesa.com',
            description: 'Stay informed with the latest tips, advice, and trends in auto glass repair from the experts at AutoGlassRepairMesa.com.'
        },
        h1: 'Auto Glass Repair Blog - Tips, Advice, and More',
        h2: 'Our Latest Articles',
        heading: 'Explore our blog for insightful articles on auto glass maintenance, repair techniques, and industry news. Stay updated with the best practices and advice from our experienced technicians.',
        posts: posts
    },
    windshieldRepair: {
        url: 'https://www.autoglassrepairmesa.com/windshield-repair',
        metaData: {
            title: 'Windshield Repair in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Professional windshield repair services in Mesa, AZ. Fix chips and cracks efficiently with AutoGlassRepairMesa.com.'
        },
        h1: 'Expert Windshield Repair Services',
        subtitle: 'Quick and Reliable Windshield Repair in Mesa, AZ',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Effective Solutions for Windshield Damage</Heading>
                <Text>Trust our skilled technicians to assess the damage to your windshield accurately and recommend the
                    most
                    appropriate solution to restore its integrity. We use advanced techniques and quality materials to
                    ensure
                    durable repairs that meet industry standards. Don't let a damaged windshield compromise your safety.
                    Contact us today for professional windshield repair services in Mesa, AZ. <br/>
                    Our windshield repair services include:
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Chip and Crack Repairs:</b> Utilizing advanced techniques to restore the integrity and
                        clarity of your windshield.
                        Our skilled technicians meticulously address chips and cracks, using industry-leading methods to
                        ensure
                        that your windshield is repaired effectively. We understand the importance of maintaining
                        visibility
                        and structural integrity, which is why we're committed to delivering top-notch repair services
                        tailored
                        to your specific needs.
                    </ListItem>
                    <ListItem>
                        <b>Long-Lasting Results:</b> Ensuring repairs are durable and prevent further damage.
                        We take pride in our workmanship and strive to provide long-lasting results that exceed your
                        expectations.
                        Our goal is not just to fix the immediate issue but to prevent future damage and maintain the
                        structural integrity of your windshield. With our attention to detail and quality materials,
                        you can trust us to deliver exceptional results that stand the test of time.
                    </ListItem>
                    <ListItem>
                        <b>Quick Service:</b> Offering prompt repairs to get you back on the road safely and quickly.
                        We understand that windshield damage can disrupt your routine and compromise your safety.
                        That's why we prioritize efficiency and offer fast, reliable service to minimize downtime
                        and inconvenience. Whether you're dealing with a small chip or a larger crack, you can
                        count on us to provide timely repairs without sacrificing quality or safety.
                    </ListItem>
                    <ListItem>
                        <b>Expert Consultation:</b> Providing personalized consultations to address your specific
                        windshield needs.
                        Our experienced team is here to answer your questions, assess the condition of your windshield,
                        and recommend the best course of action. We understand that every situation is unique, which
                        is why we take the time to listen to your concerns and provide tailored solutions that meet
                        your requirements. Trust us to provide expert guidance and exceptional service from start to
                        finish.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Contact us for efficient windshield repair solutions in Mesa, AZ. Whether you have a small chip that
                    needs
                    fixing or require a complete windshield replacement, our team at Auto Glass Repair Mesa is here to
                    help.
                    We understand the inconvenience of windshield damage and strive to provide prompt and reliable
                    service
                    to get you back on the road safely. With our commitment to quality and customer satisfaction, you
                    can
                    trust us to deliver lasting solutions for all your auto glass needs.
                </Text>

            </Stack>
        </Box>
    },
    mobileAutoGlassServices: {
        url: 'https://www.autoglassrepairmesa.com/mobile-auto-glass-services',
        metaData: {
            title: 'Mobile Auto Glass Services in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Convenient mobile auto glass repair and replacement services in Mesa, AZ. Quality service at your location from AutoGlassRepairMesa.com.'
        },
        h1: 'Professional Mobile Auto Glass Services',
        subtitle: 'On-the-Spot Auto Glass Repair and Replacement',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Convenience Meets Quality</Heading>
                <Text>Our mobile auto glass services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text>
                            <strong>On-Site Repairs:</strong> Bringing our professional services to your location for
                            maximum
                            convenience. Whether you're at home, work, or elsewhere, our skilled technicians will come
                            to you,
                            equipped to handle all your auto glass needs efficiently and effectively. With our mobile
                            repair
                            service, you can enjoy the convenience of having your auto glass repaired or replaced
                            without
                            having to leave your location. We bring the expertise and equipment directly to you,
                            ensuring
                            a seamless and hassle-free experience.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Full-Range Services:</strong> Offering everything from chip repairs to full
                            windshield replacements
                            on the go. No matter the extent of the damage to your auto glass, our mobile services ensure
                            comprehensive solutions tailored to your vehicle's requirements, ensuring your safety and
                            peace
                            of mind on the road. From minor chips and cracks to complete windshield replacements, our
                            skilled
                            technicians have the expertise and resources to address all your auto glass needs
                            efficiently
                            and effectively, wherever you may be.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Flexible Scheduling:</strong> Accommodating your busy schedule with timely and
                            efficient
                            service. We understand the demands of modern life, which is why we offer flexible scheduling
                            options
                            to fit your timetable. Our goal is to make the process as convenient as possible for you,
                            allowing
                            you to get the auto glass services you need without disrupting your daily routine. Whether
                            you
                            need repairs during your lunch break or a windshield replacement on the weekend, we work
                            with
                            you to find a time that suits your schedule, ensuring minimal disruption to your day-to-day
                            activities.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Professional Expertise:</strong> Our team consists of highly trained and experienced
                            technicians
                            who are dedicated to delivering top-notch service and exceptional results. With years of
                            experience
                            in the auto glass industry, we have the skills and knowledge to handle even the most complex
                            auto glass repairs and replacements with precision and efficiency. When you choose our
                            mobile
                            auto glass services, you can trust that your vehicle is in capable hands.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Quality Materials:</strong> We use only the highest quality materials and
                            state-of-the-art
                            equipment to ensure that your auto glass repairs and replacements are built to last. Our
                            commitment
                            to quality extends beyond our workmanship to the products we use, ensuring that your new
                            windshield
                            or repaired glass meets or exceeds industry standards for safety and durability.
                        </Text>
                    </ListItem>
                </UnorderedList>

                <Text>For mobile auto glass services in Mesa, AZ, rely on the convenience and quality of
                    <Link href="https://AutoGlassRepairMesa.com">AutoGlassRepairMesa.com</Link>. With our commitment to
                    excellence and customer satisfaction, we deliver superior results wherever you are. Schedule your
                    mobile auto glass service today and experience the convenience of professional care at your
                    doorstep.
                </Text>
            </Stack>
        </Box>
    },
    windshieldReplacement: {
        url: 'https://www.autoglassrepairmesa.com/windshield-replacement',
        metaData: {
            title: 'Windshield Replacement in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Top-quality windshield replacement services in Mesa, AZ. Get the best fit and durability with AutoGlassRepairMesa.com.'
        },
        h1: 'Best Windshield Replacement Services',
        subtitle: 'High-Quality Windshield Replacement for All Vehicle Types',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Expert Windshield Replacement</Heading>
                <Text>
                    When it comes to windshield replacement, trust the experts at Auto Glass Repair Mesa to deliver
                    exceptional service and superior results. Our windshield replacement services include:
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text>
                            <strong>Quality Glass Selection:</strong> Providing a wide range of high-quality glass
                            options suitable for
                            all vehicle models. We source our glass from trusted manufacturers to ensure durability,
                            clarity,
                            and compatibility with your vehicle's make and model.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Precision Installation:</strong> Ensuring a perfect fit and leak-proof sealing for
                            long-term
                            performance. Our technicians employ precise measurement techniques and utilize advanced
                            tools to
                            install your new windshield with accuracy and care, minimizing the risk of leaks and
                            ensuring
                            structural integrity.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Safety Compliance:</strong> Replacing windshields in accordance with safety
                            standards to ensure
                            your protection. We adhere strictly to industry safety guidelines and regulations,
                            prioritizing
                            the structural integrity of your vehicle and your safety on the road.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Convenient Scheduling:</strong> Offering flexible appointment times to accommodate
                            your busy
                            schedule. We understand the importance of convenience, which is why we offer flexible
                            scheduling
                            options tailored to your availability, making it easy to get the windshield replacement you
                            need
                            without disrupting your daily routine.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Insurance Assistance:</strong> Assisting with insurance claims to streamline the
                            reimbursement
                            process for windshield replacement. Our team works closely with insurance providers to help
                            you navigate
                            the claims process smoothly, ensuring you receive the coverage you're entitled to without
                            added stress
                            or hassle.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Lifetime Warranty:</strong> Providing peace of mind with our lifetime warranty on
                            windshield
                            replacement. We stand behind the quality of our workmanship and materials, offering
                            assurance that
                            your new windshield is protected against defects and workmanship errors for as long as you
                            own your
                            vehicle.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Customer Satisfaction Guarantee:</strong> Ensuring your complete satisfaction with
                            our
                            windshield replacement services. We are committed to delivering exceptional customer service
                            and
                            ensuring that every aspect of your experience with us exceeds your expectations. Your
                            satisfaction
                            is our top priority, and we strive to earn your trust and loyalty with every windshield
                            replacement
                            we perform.
                        </Text>
                    </ListItem>
                </UnorderedList>

                <Text>
                    For professional windshield replacement in Mesa, AZ, choose <Link
                    href="https://AutoGlassRepairMesa.com">AutoGlassRepairMesa.com</Link>.
                    Our team is committed to providing reliable service and top-quality craftsmanship to ensure your
                    satisfaction and safety on the road. With years of experience and a dedication to excellence,
                    we guarantee results that meet the highest standards. Contact us today to schedule an appointment
                    and experience the difference with Auto Glass Repair Mesa.
                </Text>

            </Stack>

        </Box>
    },
    rearWindowRepair: {
        url: 'https://www.autoglassrepairmesa.com/rear-window-repair',
        metaData: {
            title: 'Rear Window Repair in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Expert rear window repair services for all vehicle types in Mesa, AZ. Trust AutoGlassRepairMesa.com for reliable and efficient service.'
        },
        h1: 'Comprehensive Rear Window Repair Services',
        subtitle: 'Efficient Repair Solutions for Your Vehicle’s Rear Window',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Specialized Rear Window Care</Heading>
                <Text>Our rear window repair services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text>
                            <strong>Damage Assessment:</strong> Thorough examination to determine the best repair
                            approach. Our
                            technicians carefully assess the extent of the damage to your auto glass, considering
                            factors such
                            as the size, location, and severity of the chip or crack. This comprehensive evaluation
                            allows us
                            to recommend the most suitable repair solution to restore the integrity and functionality of
                            your
                            windshield or glass.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Quality Repairs:</strong> Utilizing the finest materials and techniques for lasting
                            results. We believe in delivering quality craftsmanship that stands the test of time. That's
                            why
                            we use premium materials and proven repair techniques to ensure that your auto glass repairs
                            are
                            durable and reliable. With our attention to detail and commitment to excellence, you can
                            trust
                            that your vehicle is in good hands.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Safety Focus:</strong> Ensuring repairs meet safety standards to protect you and
                            your
                            passengers. Your safety is our top priority. We adhere strictly to industry safety standards
                            and
                            guidelines when performing auto glass repairs, ensuring that the structural integrity of
                            your
                            vehicle is maintained and that you and your passengers are protected in the event of an
                            accident
                            or collision. Trust us to prioritize your safety and provide peace of mind on the road.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Convenient Service:</strong> We understand that your time is valuable. That's why we
                            strive to provide convenient and efficient service that fits into your busy schedule. Our
                            streamlined repair process minimizes downtime and inconvenience, allowing you to get back on
                            the road quickly and safely. Whether you need a quick chip repair or a full windshield
                            replacement,
                            you can rely on us to deliver prompt and professional service with minimal disruption to
                            your
                            day.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Customer Satisfaction:</strong> At the heart of everything we do is a commitment to
                            customer satisfaction. We go above and beyond to ensure that every customer receives the
                            highest level of service and care. From the moment you contact us to the completion of your
                            auto glass repair, we strive to exceed your expectations at every step of the process. Your
                            satisfaction is our ultimate goal, and we won't rest until you're completely happy with the
                            results.
                        </Text>
                    </ListItem>
                </UnorderedList>

                <Text>
                    Choose AutoGlassRepairMesa.com for top-tier rear window repair services in Mesa, AZ. Whether you've
                    experienced a minor crack or significant damage to your rear window, our team is here to help. We
                    specialize in providing efficient and effective rear window repair solutions tailored to your
                    vehicle's
                    needs. With our commitment to quality craftsmanship and customer satisfaction, you can trust us to
                    restore the integrity and functionality of your rear window promptly and professionally. Don't let
                    damaged auto glass compromise your safety and comfort on the road. Contact us today to schedule
                    your rear window repair service with AutoGlassRepairMesa.com.
                </Text>

            </Stack>
        </Box>
    },
    sideWindowRepair: {
        url: 'https://www.autoglassrepairmesa.com/side-window-repair',
        metaData: {
            title: 'Side Window Repair in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Professional side window repair for cars and trucks in Mesa, AZ. Fast and efficient service from AutoGlassRepairMesa.com.'
        },
        h1: 'Specialized Side Window Repair Services',
        subtitle: 'Quick and Reliable Side Window Repairs for All Vehicles',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Expert Repair for Side Windows</Heading>
                <Text>Our side window repair services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text>
                            <strong>Damage Repair:</strong> Addressing issues from minor chips to complete window
                            damage. No matter
                            the extent of the damage to your rear window, our skilled technicians are equipped to handle
                            a wide range of repair needs. Whether it's a small chip or a larger crack, we have the
                            expertise
                            and resources to provide effective solutions that restore the integrity and appearance of
                            your
                            rear window.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Swift Service:</strong> Ensuring quick repairs to get your vehicle back in optimal
                            condition. We understand the inconvenience of rear window damage, which is why we prioritize
                            swift and efficient service to minimize downtime for our customers. With our streamlined
                            repair
                            process and experienced technicians, you can trust us to restore your rear window promptly
                            and get you back on the road safely.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Quality Assurance:</strong> Using high-quality materials for durable and effective
                            repairs. When it comes to rear window repair, we believe in using only the best materials
                            and techniques to ensure lasting results. From the adhesive we use to seal the repair to
                            the glass itself, we prioritize quality and durability to ensure that your rear window
                            is restored to its original condition and provides reliable performance for years to come.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Professional Technicians:</strong> Our team consists of highly skilled and
                            experienced
                            technicians who are dedicated to providing top-notch rear window repair services. With years
                            of experience in the auto glass industry, our technicians have the expertise to handle
                            even the most challenging rear window repairs with precision and efficiency, ensuring
                            exceptional results every time.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Customer Satisfaction:</strong> At AutoGlassRepairMesa.com, your satisfaction
                            is our top priority. We strive to exceed your expectations with every rear window repair
                            service we provide. From the moment you contact us to the completion of the repair,
                            we are committed to delivering a positive experience and ensuring that you are completely
                            satisfied with the results.
                        </Text>
                    </ListItem>
                </UnorderedList>

                <Text>
                    For dependable side window repair in Mesa, AZ, turn to the expertise of AutoGlassRepairMesa.com.
                    Whether
                    your side window has been damaged by a rock, accident, or vandalism, our team is here to help. We
                    specialize
                    in providing reliable and efficient side window repair services tailored to your vehicle's make and
                    model.
                    With our commitment to quality craftsmanship and customer satisfaction, you can trust us to restore
                    the integrity and functionality of your side window promptly and professionally. Don't let a damaged
                    side window compromise your safety and security on the road. Contact us today to schedule your side
                    window repair service with AutoGlassRepairMesa.com.
                </Text>

            </Stack>
        </Box>
    },
    ADASCalibration: {
        url: 'https://www.autoglassrepairmesa.com/adas-calibration',
        metaData: {
            title: 'ADAS Calibration in Mesa, AZ | AutoGlassRepairMesa.com',
            description: 'Ensure your vehicle’s safety features work correctly with our ADAS calibration services in Mesa, AZ.'
        },
        h1: 'Expert ADAS Calibration Services',
        subtitle: 'Precision Calibration for Advanced Driver Assistance Systems',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h2'}>Maintaining Your Vehicle’s Safety Features</Heading>
                <Text>Our ADAS calibration services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <Text>
                            <strong>Accurate Calibration:</strong> Using advanced tools to ensure precise calibration of
                            your vehicle’s
                            ADAS features. Our technicians employ state-of-the-art equipment and techniques to calibrate
                            your vehicle's Advanced Driver Assistance Systems (ADAS) accurately. We understand the
                            importance
                            of proper calibration for the functionality and safety of your vehicle's ADAS features, and
                            we are committed to ensuring that your vehicle meets the manufacturer's specifications.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Post-Replacement Calibration:</strong> Essential calibration services following
                            windshield
                            replacement. After replacing your windshield, it's crucial to recalibrate the ADAS systems
                            to ensure optimal performance and accuracy. Our post-replacement calibration services
                            help restore the functionality of your vehicle's ADAS features, ensuring that they operate
                            as intended and provide you with the safety and convenience you rely on.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Experienced Technicians:</strong> Skilled professionals trained in the latest ADAS
                            technology. Our technicians undergo extensive training and certification in the latest
                            ADAS technology and calibration techniques. With their expertise and attention to detail,
                            you can trust that your vehicle is in capable hands. We are committed to delivering
                            superior service and ensuring that your vehicle's ADAS systems are calibrated
                            accurately and efficiently.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>State-of-the-Art Facility:</strong> Equipped with advanced technology and tools, our
                            state-of-the-art facility is designed to provide comprehensive ADAS calibration services
                            for a wide range of vehicle makes and models. We invest in the latest equipment and
                            infrastructure to ensure that your vehicle receives the highest quality calibration
                            services available.
                        </Text>
                    </ListItem>
                    <ListItem>
                        <Text>
                            <strong>Comprehensive Testing:</strong> We perform thorough testing and verification of
                            your vehicle's ADAS systems to ensure that they are functioning correctly after calibration.
                            Our technicians conduct rigorous tests to confirm the accuracy and reliability of
                            the calibration, giving you peace of mind knowing that your vehicle's safety features
                            are operating at peak performance.
                        </Text>
                    </ListItem>
                </UnorderedList>

                <Text>
                    For expert ADAS calibration services in Mesa, AZ, trust the professionals at
                    AutoGlassRepairMesa.com.
                    Our experienced technicians are trained in the latest ADAS technology and calibration techniques
                    to ensure the accuracy and reliability of your vehicle's safety systems. Whether you need
                    accurate calibration for your ADAS features after windshield replacement or routine maintenance
                    to keep your systems performing optimally, we have the expertise and resources to meet your
                    needs. With our commitment to quality and customer satisfaction, you can rely on us
                    to provide reliable and efficient ADAS calibration services that meet the highest standards.
                    Contact us today to schedule your appointment with AutoGlassRepairMesa.com.
                </Text>

            </Stack>
        </Box>
    },
    testimonials: {
        h1: 'Why they love us',
        h2: 'Testimonials',
        heading: 'See What Our Customers Say About AutoGlassRepairMesa.com',
        testimonials: [
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
                name: 'Emily R.',
                title: 'Mesa, AZ',
                quote: '"I had a great experience with AutoGlassRepairMesa.com. They fixed my windshield quickly and it looks good as new. The technician was professional and the service was reasonably priced."'
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0425d',
                name: 'Mark T.',
                title: 'Mesa, AZ',
                quote: '"After a rock hit my windshield, I called AutoGlassRepairMesa.com and they were fantastic. They came to my location and the repair was done perfectly. Highly recommend their mobile service!"'
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0804d',
                name: 'Sara P.',
                title: 'Mesa, AZ',
                quote: '"AutoGlassRepairMesa.com is my go-to for auto glass issues. They have replaced my car’s side window and the process was smooth and hassle-free. Great quality and excellent customer service."'
            },
        ]
    },
    '404': {
        metaData: {
            title: 'Page Not Found - AutoGlassRepairMesa.com | Auto Glass Specialists',
            description: 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.'
        },
        h1: '404 - Page Not Found',
        subtitle: 'Oops! Looks like you\'ve hit a crack in the road.',
        p: 'The page you’re looking for can’t be found. But don’t worry, you can navigate back to our homepage or explore our services to find what you need in auto glass repair and replacement.',
    },
}