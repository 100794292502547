import React from 'react'
import Helmet from 'react-helmet'
import {useLocation} from "react-router-dom";
import {SiteData} from "../../Constants/siteData";
import {getMetaDescription, getMetaTitle} from "../../Utils/metaHelpers";
import useBlogPosts from "../../Hooks/useBlogPosts";


export const SEO = ({
                        image,
                        title,
                        description,
                        url,
                        type,
                        siteName,
                        keywords,
                        useProtocol
                    }) => {
    const {posts} = useBlogPosts()
    const location = useLocation();
    const canonicalUrl = `${SiteData.homepage.url}${location.pathname}`;

    const selectedPost = posts.find((post) => location.pathname.includes(post.slug));
    const tags = [
        {name: 'viewport', content: "width=device-width,initial-scale=1,viewport-fit=cover"},
        {name: 'description', content: description || getMetaDescription(location?.pathname, selectedPost?.metaDescription)},
        {name: 'robots', content: 'index,follow'},
        {name: 'keywords', content: SiteData.keywords},
        {name: 'title', content: title || getMetaTitle(location?.pathname, selectedPost?.title)},
        {name: "twitter:description", content: description || getMetaDescription(location?.pathname, selectedPost?.metaDescription)},
        {name: "og:description", content: description || getMetaDescription(location?.pathname, selectedPost?.metaDescription)},
        {name: "og:image", content: image || SiteData.ogImage},
        {name: "og:type", content: 'website'},
        {name: "og:url", content: location?.pathname},
        {name: "og:site_name", content: title || getMetaTitle(location?.pathname, selectedPost?.title)},
        {name: "twitter:url", content: location?.pathname},
        {name: "twitter:image", content: image || SiteData.ogImage},
        {name: "twitter:card", content: 'summary'},
        {name: 'twitter:title', content: title || getMetaTitle(location?.pathname, selectedPost?.title)},
    ]
    return (
        <Helmet title={title} script={[
            {
                src: `https://www.googletagmanager.com/gtag/js?id=G-SKLL8GJ1WT`,
                type: 'text/javascript',
                async: true,
            },
            {
                type: 'text/javascript',
                innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)} gtag('js', new Date()); gtag('config', 'G-SKLL8GJ1WT');`,
            }
        ]}>
            <title>{tags.find((tag) => tag.name === 'title').content}</title>
            {tags.map(({name, content}, idx) =>
                <meta data-react-helmet="true" name={name} content={content} key={`${`name`}-${idx}`}/>)
            }
            <link rel="canonical" href={canonicalUrl}/>
        </Helmet>
    )
}